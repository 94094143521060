<template>
 <div>
    <v-row no-gutters class="pa-2">
        <v-col cols="12">
          <v-card v-if="!isOnline" flat class="text-center pa-4">
            
            <p style="margin-top: 120px;">
              <v-btn  x-large fab><v-icon>mdi-wifi-off</v-icon></v-btn>
            </p>
              Please connect to the Internet 
          </v-card>
          <v-card flat v-else>
                <v-row dense no-gutters > 
                   <v-col cols="6"  class="pa-1">
                </v-col>
                  <v-col cols="6"  class="pa-1">
                   <v-select
                   outlined
                   v-model="search"
                   dense
                   label="Select game"
                   :items="$store.state.games"
                   />
                </v-col>
                </v-row>
                <div id="PrintReport">
                  <div class="pt-3 pb-3  text-subtitle-2">
                  {{search}} Results
                  </div>
                  <table class="print_table md-font" >
                      <tr>
                          <th class="text-left">DRAW</th>
                          <th class="text-right">RESULT</th>
                      </tr>
                      <tr v-for="(item, index) in FILTERED" :key="index">
                          <td> {{item.game}} - {{$moment(item.date).format('MMM DD, YYYY') + ' ' + item.time}} </td>
                        
                          <td class="text-right">{{ExtractRes(item.combination, item.game)}}</td>
                      </tr>
                  </table>
                </div>
            </v-card>
        </v-col>
    </v-row>
</div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      to_menu: false,
        search:'ALL GAMES',
        from_date:'',
        to_date: '',
        date_range:"",
        salesman:{},
        transactions:[],
        per_type:'Overall'
    }),
    created() {
      this.PING()
     this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
        this.GET_RESULTS()
    },
     mounted(){
       this.setLoggedIn(true)
        if (this.user == null) {
          this.$router.push('/')
        }else {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
        }
    },
    computed:{
      isOnline(){
        return this.$store.state.is_online
      },
      user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      },
      FILTERED(){
        return (this.RESULTS.reduce((res,item)=>{
          if(this.search == "All Games") {
             res.push(item)
          } else {
            if(item.game == this.search) {
              res.push(item)
            }
          }
            return res
        }, [])) 
      },
      RESULTS(){
        return  this.$store.state.results
      },
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn', 'setOverlayLoading']),
      ExtractRes(combinations, game){
        var res = combinations.split('-')
        // if(game == '2D' || game == 'LASTTWO') {
        //   return res[0] + " - " +res[1]
        // } else if(game == '3D') {
        //   return res[0] + " - " +res[1] + " - " +res[2]
        // } else if(game == '4D') {
        //   return res[0] + " - " +res[1] + " - " +res[2] + " - " +res[3]
        // } else if(game == 'PICK3') {
        //   return res[0] + " - " +res[1] + " - " +res[2] + " - " +res[3]  + " - " +res[4] + " - " +res[5] 
        // }
        if(game == '3D') { 
          return res[0] + " - " +res[1] + " - " +res[2]
        } else if(game == '4D') {
          return res[0] + " - " +res[1] + " - " +res[2] + " - " +res[3]
        }  
        else {
          return res[0] + " - " +res[1]
        }
      }
    },
  }
</script>